import React, { useEffect, useState } from "react"
import { graphql, Link } from "gatsby"
import { Helmet } from "react-helmet"

import logoWhite from "../images/enimeris-logo-website-white.png";
import there from "../images/hand-point-right.svg"
import arrowLeft from "../images/arrow-left.svg"


import clearWhite from "../images/times-solid.svg";
import Footer from "../components/FooterMenu"
import htmlDecode from "html-entities-decoder";
import ApplicantForm from "./../components/Global/ApplicantForm"
import moment from "moment"
import Modal from 'react-modal';
import "./../components/jobTemplate.css"

const customStyles = {
    overlay: {
        zIndex: "3"
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        padding: '0px',
    }
};

function JobTemplate(props) {

    const [modalIsOpen, setIsOpen] = useState(false);


    console.log(props)
    const data = props.data.wpgraphql.job;

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    function extractContent(s) {
        var span = document.createElement('span');
        span.innerHTML = s;
        let pContext = span.querySelectorAll("p").innerText;
        return pContext;
    };

    function getTimeOption(op) {
        var tmp = "";
        switch (op) {
            case "Πλήρης Απασχόληση Ορισμένου Χρόνου":
                tmp = "FULL_TIME,TEMPORARY";
            case "Πλήρης Απασχόληση Αορίστου Χρόνου":
                tmp = "FULL_TIME";
            case "Μερική Απασχόληση Ορισμένου Χρόνου":
                tmp = "PART_TIME,TEMPORARY";
            case "Μερική Απασχόληση Αορίστου Χρόνου":
                tmp = "PART_TIME";
            case "Πρακτική Πλήρους απασχόλησης":
                tmp = "FULL_TIME,INTERN";
            case "Πρακτική Μερικής απασχόλησης":
                tmp = "PART_TIME,INTERN";
            case "Σύμβαση Έργου":
                tmp = "CONTRACTOR";
            case "Εθελοντική εργασία ορισμένου χρόνου":
                tmp = "TEMPORARY,VOLUNTEER";
            case "Εθελοντική εργασία Αορίστου χρόνου":
                tmp = "VOLUNTEER,TEMPORARY";
            case "Μεροκάματο":
                tmp = "PER_DIEM";
            case "Κατ αποκοπή αμοιβή":
                tmp = "CONTRACTOR";
            default:
                tmp = "FULL_TIME";
        }
        return tmp;
    }

    //here are the following options: "FULL_TIME", "PART_TIME", "CONTRACTOR", "TEMPORARY", "INTERN", "VOLUNTEER", "PER_DIEM", "OTHER"

    return (
        <div>

            <Helmet>
                <html lang={"el"} />
                <title>{data.seo.title}</title>
                <meta name="description" content={data.seo.description} />
                <link rel="canonical" href={data.seo.canonical} />
                <meta
                    name="viewport"
                    content="width=device-width,initial-scale=1,shrink-to-fit=no,viewport-fit=cover"
                />
                <meta property="og:title" content={data.seo.opengraphTitle} />
                <meta property="og:description" content={data.seo.opengraphDescription} />
                <meta property="og:image" content={data.seo.opengraphImage ? data.seo.opengraphImage.sourceUrl : ""} />
                <meta property="og:image:width" content="598" />
                <meta property="og:image:height" content="399" />


                {typeof window != "undefined" && (
                    <script type="application/ld+json">
                        {`{
                            "@context": "http://schema.org/",
                            "@type": "JobPosting",
                        
                            "title": "${htmlDecode(data.title)}",
                        
                            "datePosted": "${moment(data.date).format("DD-MM-YYYY")}",
                            //needs to be included at the gray frame
                        
                            "description": "${extractContent(data.content)}",
                        
                            "employmentType": "${getTimeOption(data.content)}",
                        
                            "jobImmediateStart": true
                        
                        }`}
                    </script>
                )}

            </Helmet>

            <div>
                <div className="text-white synergates-intro flex justify-center">
                    <Link to={"/"}>
                        <img className="w-56 md:w-64 m-auto py-8" src={logoWhite} alt="white-logo" />
                    </Link>
                </div>
            </div>
            <div className="container m-auto py-16 flex flex-wrap">
                <div className="w-full flex-wrap option-panel flex">
                    <div className="pb-8 w-full md:w-2/3">
                        <Link className="text-md text-white flex" to={"/synergates"}>
                            <span>
                                <img src={arrowLeft} className="w-4 mr-2" />
                            </span>
                            <span>
                                Επιστροφή στις θέσεις εργασίας
                            </span>
                        </Link>
                        <h1 className="text-3xl text-white pt-4">
                            {htmlDecode(data.title)}
                        </h1>
                    </div>
                    <div className=" w-full md:w-1/3">
                        <button onClick={openModal} className="apply-bttn flex items-center"><img className="w-8 pr-2" src={there} /><span>Υποβολή Αίτησης</span></button>
                    </div>
                </div>
            </div>
            <div className="flex flex-wrap container m-auto mb-16">
                <div className="w-full lg:w-2/3 px-4 md:px-0">
                    <div className="contentEditor" dangerouslySetInnerHTML={{ __html: data.content }} />
                </div>
                <div className="w-full lg:w-1/3">
                    <div className="summary">
                        <h4>Πληροφορίες θέσης</h4>
                        <table className="w-full">
                            <tbody>
                                {data.jobAcf.specificLocation && (
                                    <tr>
                                        <td>Περιοχή:</td>
                                        <td className="text-right">{data.jobAcf.specificLocation}</td>
                                    </tr>
                                )}
                                {data.jobAcf.workingHours && (
                                    <tr>
                                        <td>Τύπος απασχόλησης:</td>
                                        <td className="text-right">{data.jobAcf.workingHours}</td>
                                    </tr>
                                )}
                                {data.jobAcf.referralCode && (
                                    <tr>
                                        <td>Αριθμός αναφοράς:</td>
                                        <td className="text-right">{data.jobAcf.referralCode}</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        <h4 className="pt-4">Επικοινωνία</h4>
                        <div className="flex">
                            <div className="w-2/3 text-sm">
                                <span>Για επιπρόσθετες πληροφορίες σχετικά με τη θέση, καλέστε μας στο
                                    <span className="pl-1">{data.jobAcf.contactPhoneNumber ? data.jobAcf.contactPhoneNumber : "2310545009"}</span>
                                    .
                                </span>
                            </div>
                            <div className="w-1/3">

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer footerMenu={props.data.wpgraphql.footer} />

            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                className={"applicant-modal"}
                contentLabel="Applicant Modal"
            >
                <button onClick={closeModal} className="appl-close-bttn z-20">
                    <img className="w-6" src={clearWhite} alt="white-clear" />
                </button>
                <ApplicantForm allowPhoneNumber={data.jobAcf.allowPhoneNumber} allowCv={data.jobAcf.allowCv} jobId={data.databaseId} jobName={htmlDecode(data.title)} />
            </Modal>
        </div>
    )
}

export default JobTemplate

export const pageQuery = graphql`
    query GET_JOB($id: ID!) {
        wpgraphql {
            footer: menu(id:"bmF2X21lbnU6MjQ="){
                menuId
                id
                slug
                name
                menuItems {
                    edges {
                        node {
                            label
                            url
                            childItems {
                                edges {
                                    node {
                                        label
                                        url
                                    }
                                }
                            }
                        }
                    }
                }
            }
            job(id: $id) {
                seo {
                    canonical
                    title
                    metaDesc
                    opengraphDescription
                    opengraphTitle
                    opengraphImage {
                        sourceUrl
                    }
                }
                databaseId
                date
                title
                slug
                content
                jobAcf {
                    specificLocation
                    workingHours
                    referralCode
                    allowPhoneNumber
                    allowCv
                    contactPhoneNumber
                }
            }
        }
    }
    `

